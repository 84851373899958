.contact_footer {
    display: grid;
    grid-template-columns: 35vw 60vw;
    grid-gap: 40px;
    align-items: center;
    justify-content: center;
    color: black;
}

.contact_info_text {
    display: flex;
    text-align: center;
    text-decoration: none;
    color: black;
    font-weight: 300;
}

.contact-main {
    margin: 20px;
}

.main-Header {
    display: flex;
    text-align: center;
    width: 100%;
}

.all_details {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    height: 100vh;
}

.details {
    border-radius: 5px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    background: #f3f1f1;
    color: rgb(0, 0, 0);
    transition: all 0.5s linear;
}

.details:hover {
    background: #c5c5c5c7;
}

.address {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
}

.contact_info_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact_info {
    width: 100%;
}

.contact_info_item i {
    color: white;
}

.contact_info_title {
    color: black;
    font-weight: 600;
    font-size: 1.0rem;
    text-transform: uppercase;
}

*:focus {
    outline: none;
}