 .footer {
     position: relative;
     bottom: 0;
     right: 0;
     width: 100%;
     background-color: #000;
     padding: 25px 0;
 }

 .footer-text {
     text-align: center;
     color: white;
 }