@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');

* {
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}

.content {
    margin-top: 15vh;
}

.home {
    min-height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../Assets/factory-2.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    padding: 0 0rem;
    position: relative;
    overflow: hidden !important;
}

.home .banner {
    color: rgb(255, 255, 255);
    font-size: 300%;
    background: #00000096;
    padding: 10px;
    margin-bottom: 1.5rem;
    text-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, .3);
}

.home .slogan {
    color: #eee;
    font-size: 1.5rem;
    font-weight: 400;
}

@media (max-width:768px) {
    html {
        font-size: 50%;
    }

    .home {
        min-height: 100vh;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../Assets/factory-2.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        display: flex;
        background-color: black;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        text-align: center;
        padding: 0 0rem;
        position: relative;
        overflow: hidden !important;
    }

    .home .slogan {
        font-size: 1.7rem;
    }

    .content {
        margin-top: -15vh;
    }
}