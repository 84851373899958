.Main-container-service {
    margin: 20px 0px;
}

.services {
    background: #ffffff;
    padding: 15px 0;
    color: #fff;
    display: flex;
    text-align: center;
}

.common {
    margin: 20px auto;
    text-align: center;
}

.mainHeader {
    font-size: 32px;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
}

.mainContent {
    font-size: 16px;
    color: #000000;
}

.commonBorder {
    background: #9e000b;
    height: 3px;
    width: 100px;
    margin: 30px auto;
}

@media (max-width: 765px) {
    .container {
        width: 100%;
        padding: 0 50px;
    }

    .common {
        width: 100%;
    }
}