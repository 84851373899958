@import url("https://fonts.googleapis.com/css2?family=Muli6display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "muli", sans-serif;
}

.navbar a {
    font-size: 1.3rem;
    margin: 7px;
    text-transform: capitalize;
}

.navbar a:hover {
    color: rgb(5, 168, 186) !important;
}

.navbar-items {
    justify-content: end;
}

@media (max-width:991px) {
    .navbar-nav {
        text-align: center;
    }
}